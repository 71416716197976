import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import css from "@emotion/css"
export default function Index() {
  const slike = useStaticQuery(graphql`
    {
      bg: file(name: { regex: "/zgrade/" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      si: file(name: { regex: "/tim-1/" }) {
        sharp: childImageSharp {
          fixed(quality: 100, width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      nk: file(name: { regex: "/s2-naslovna/" }) {
        sharp: childImageSharp {
          fixed(quality: 100, width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      fluid={[
        "linear-gradient(rgba(0,10,30,0.9),rgba(0,10,30,0.9))",
        slike.bg.sharp.fluid,
      ]}
    >
      <div className="flex items-center justify-center min-h-screen ">
        <div className="container text-center md:-mt-12">
          <div className="flex flex-wrap items-center justify-center py-12">
            <Link
              to="/ordinacija-1"
              className="block w-full mb-6 text-blue-900 bg-white md:m-6 md:w-1/4 hover:bg-blue-200"
            >
              <BackgroundImage
                css={css`
                  &::after,
                  &::before {
                    background-position: center center;
                    background-size: cover;
                  }
                `}
                style={{
                  backgroundSize: "",
                  backgroundPosition: "",
                }}
                fluid={slike.si.sharp.fixed}
              >
                <div
                  css={css`
                    padding-bottom: 80%;
                    width: 100%;
                  `}
                ></div>
              </BackgroundImage>
              <div className="p-4">
                <h5 className="my-4 text-lg font-bold ">
                  Ordinacija Smiljevac 1
                </h5>
                <h3>Martina Atelj,</h3>
                <p className="mb-0">dr. med. </p>
                <h3>Marijana Šindija, </h3>
                <p>med. sestra</p>
              </div>
            </Link>
            <Link
              to="/ordinacija-2"
              className="block w-full mb-6 text-blue-900 bg-white md:m-6 md:w-1/4 hover:bg-blue-200"
            >
              <BackgroundImage
                css={css`
                  &::after,
                  &::before {
                    background-position: center center;
                    background-size: cover;
                  }
                `}
                style={{ backgroundSize: "", backgroundPosition: "" }}
                fluid={slike.nk.sharp.fixed}
              >
                <div
                  css={css`
                    padding-bottom: 80%;
                    width: 100%;
                  `}
                ></div>
              </BackgroundImage>
              <div className="p-4">
                <h5 className="my-4 text-lg font-bold ">
                  Ordinacija Smiljevac 2
                </h5>
                <h3>Marta Despotović,</h3>
                <p className="mb-0">dr. med. </p>
                <h3> Kristina Vukoša,</h3>
                <p>med. sestra</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}
